<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование инструкции"
        update
        :fields="['type', 'name', 'content']"
        url-update="/hint/update/:id"
        url-back="/hint"
        action-one="hint/one"
    >
        <job-hint-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import JobHintForm from '@/components/forms/JobHintForm.vue'
export default {
    name: 'HintUpdate',
    components: { FormView, JobHintForm }
}
</script>